'use client';
import { useEffect } from 'react';
/**
 * Triggers `onChange` when another browser tab instance mutates the LS value.
 */
export const useWatchLocalStorageValue = ({ key: watchKey, onChange, }) => {
    function handleStorageChange({ key, newValue }) {
        if (key === watchKey)
            onChange(newValue);
    }
    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []); // eslint-disable-line
};
